<template>
  <div class="support">
    <div class="supportBox">
      <div class="p1 s1 title">Vancheen {{ $t.support.title }}</div>
      <ul class="ul1">
        <li class="li2">
          <span class="s2"></span>
          <span class="s3">{{ $t.support.dateHour }}</span>
        </li>
        <li class="li2">
          <span class="s2"></span>
          <span class="s3">{{ $t.support.dateWeek }}</span>
        </li>
        <li class="li2">
          <span class="s2"></span>
          <span class="s3">{{ $t.support.email }} : {{ email }}</span>
        </li>
      </ul>
    </div>

    <div class="supportBox">
      <section class="itemBox newItemBox">
        <div class="itemSelect" @click="showPicker = true">
          <div class="selectText" v-if="report.STId">{{ report.title }}</div>
          <div class="selectText" v-else>{{ $t.support.select }}</div>
          <img src="~assets/img/order/icon_order_right.png" class="icon" />
        </div>
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="columns"
            @cancel="showPicker = false"
            @confirm="handleFreightDidSelected"
          />
        </van-popup>
      </section>
      <section class="itemBox">
        <textarea
          class="address"
          v-model="report.message"
          rows="5"
          :placeholder="$t.support.enterSupport"
        ></textarea>
      </section>
    </div>

    <div class="supportBox">
      <section class="itemBox newItemBox">
        <div class="itemSelect">
          <div class="selectText">{{ $t.support.contactWay }}</div>
        </div>
      </section>
      <section class="itemBox">
        <textarea
          class="address"
          v-model="report.contact"
          rows="5"
          :placeholder="$t.support.enterEmail"
        ></textarea>
      </section>
    </div>

    <div class="submitBox" @click="submit">
      <div class="submit">{{ $t.meta.submit }}</div>
    </div>

    <div class="submitBox" @click="gotoSupportList">
      <div class="notice">{{ $t.support.supportList }}</div>
    </div>

    <div class="copyrightBox">
      VEEGEN (HONGKONG) ENTERPRISE COMPANY LIMITED PROLINKER
      HOLDING(GUANGZHOU)CO.,LTD
      <div>{{ $t.support.copyRight }}</div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Field, Picker, Popup } from "vant";
import Schema from "async-validator";

Vue.use(Popup).use(Field).use(Picker);
export default {
  name: "Support",
  data() {
    return {
      userId: "",
      type: "B",
      language: "",
      phoneNumber: "+86 20 81194476",
      email: "service@vancheen.com",
      showPicker: false,
      columns: [],
      report: {
        title: "",
        STId: "",
        message: "",
        contact: "",
      },
    };
  },
  mounted() {
    this.userId = JSON.parse(localStorage.getItem("auth")).userId;
    this.getSeriveType();
    var localLanguage =
      navigator.language ||
      navigator.userLanguage ||
      localStorage.getItem(kLanguage) ||
      "en";
    this.language = localLanguage.substr(0, 2);
  },
  methods: {
    callNumber(number) {
      this.$commonEnv.commonAction(
        this.$commonEnv.actionType.makePhoneCall,
        number
      );
    },
    handleFreightDidSelected(option) {
      this.report.title = option.text;
      this.report.STId = option.value;
      this.showPicker = false;
    },
    getSeriveType() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      this.$api.system
        .getSeriveType()
        .then((res) => {
          const data = res.data;
          this.formatData(data);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
        })
        .catch((error) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    formatData(items) {
      items.forEach((item) => {
        let column = {
          text:
            this.language == "zh"
              ? item.ServiceTypeName
              : item.ServiceTypeNameEn,
          value: item.STId,
        };
        this.columns.push(column);
      });
    },
    gotoSupportList() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: `user/supportList`,
        name: "SupportList",
        query: [],
      });
    },
    submit() {
      const rule = {
        STId: { required: true, message: this.$t.support.enterType },
        message: { required: true, message: this.$t.support.enterSupport },
        contact: { required: true, message: this.$t.support.contactWay },
      };
      const validator = new Schema(rule);
      validator
        .validate(this.report)
        .then((_) => {
          this.$commonMethod.showLoading();
          let param = {
            userId: this.userId,
            PlatformType: this.type,
            STId: this.report.STId,
            Title: this.report.title,
            Content: this.report.message,
            ContactWay: this.report.contact,
          };
          return this.$api.system.addProblemFeedback(param);
        })
        .then((res) => {
          this.$commonMethod.hideLoading();
          this.$commonMethod.dialogAlert(
            res.message ? res.message : res.Message,
            () => {
              this.$commonEnv.commonAction(this.$commonEnv.actionType.pop);
            }
          );
        })
        .catch((err) => {
          if (err.errors) {
            this.$commonMethod.showNotify({
              type: "danger",
              titleMsg: err.errors[0].message,
            });
          } else {
            this.$commonMethod.hideLoading();
            this.$commonMethod.showToast({
              type: "error",
              titleMsg: err.message,
            });
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
@mixin newBox1 {
  border-radius: 8px;
  background-color: #ffffff;
  margin-top: 0.3rem;
}
@mixin boxLeft {
  max-width: 35%;
  text-align: left;
  color: #000000;
}
@mixin boxright {
  max-width: 65%;
  text-align: right;
  font-size: 0.4rem;
  color: #000000;
  font-weight: bold;
  background: none;
  outline: none;
  border: none;
}
.support {
  width: 100%;
  .p1 {
    margin: 0px 0px 0px 0px;
    font: 1.4rem "PingFang SC Semibold";
    color: #1b1c33;
    -webkit-text-stroke: #1b1c33;
  }

  .p2 {
    margin: 0px 0px 0px 0px;
    font: 1.4rem "PingFang SC";
    color: #1b1c33;
    -webkit-text-stroke: #1b1c33;
  }

  li.li2 {
    padding: 0px 0.8rem 0.8rem 0.8rem;
    font: 1rem "PingFang SC";
    color: #1b1c33;
    -webkit-text-stroke: #1b1c33;
  }

  span.s1 {
    font: 17px "PingFang SC";
    font-kerning: none;
  }

  span.s2 {
    -webkit-text-stroke: 0px #000000;
  }

  span.s3 {
    font-kerning: none;
    margin-right: 0.3rem;
  }

  span.s4 {
    font-kerning: none;
    -webkit-text-stroke: 0px #000000;
  }

  ul.ul1 {
    list-style-type: none;
    margin-top: 0.2rem;
  }
  .title {
    text-align: center;
    font: 1.2rem "PingFang SC Semibold";
    color: #1b1c33;
    -webkit-text-stroke: #1b1c33;
    font-weight: bold;
    padding: 0.8rem 0;
  }

  .supportBox {
    @include newBox1;
    .itemBox {
      @include publicFlex;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      padding: 10px;
      .itemBoxLeft {
        @include boxLeft;
      }
      .itemBoxRight {
        @include boxright;
      }
      .address {
        width: 100%;
        border: none;
      }
      .itemSelect {
        .selectText {
          display: inline-block;
        }
        .icon {
          width: 12px;
          height: 12px;
          margin-left: 10px;
          flex-shrink: 0;
        }
      }
    }
    .newItemBox {
      border-bottom: 1px solid #f6f6f6;
      color: #333;
    }
  }
  .submitBox {
    width: $publicWidth;
    margin: 5% auto;
    .submit {
      position: relative;
      width: 100%;
      margin: 0 auto;
      border-radius: 3rem;
      padding: 0.8rem 0;
      text-align: center;
      background: linear-gradient(left, #71d283, #01aaa3);
      color: #ffffff;
      font-size: 1rem;
      line-height: 1;
    }
    .notice {
      width: 100%;
      color: #666;
      text-align: center;
      font-size: 14px;
    }
  }
  .copyrightBox {
    margin: 30% auto 10% auto;
    width: 90%;
    color: #999;
    text-align: center;
    font-size: 14px;
  }
}
</style>
